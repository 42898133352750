import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Modal, Drawer } from 'components/modals/GalleryModal';

interface PromotionModalProps {
  isOpen: boolean;
  onClose: () => void;
  src: string;
}

const PromotionModal: FC<PromotionModalProps> = ({ isOpen, onClose, src }) => {
  const isDrawer = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {isDrawer ? (
        <Drawer isOpen={isOpen} onClose={onClose} src={src} />
      ) : (
        <Modal isOpen={isOpen} onClose={onClose} src={src} />
      )}
    </>
  );
};

export default PromotionModal;
