import React, { FC, useState, useCallback } from 'react';
import { Box } from '@mui/material';
import GalleryModal from 'components/modals/GalleryModal/GalleryModal';

interface GalleryImageProps {
  src: string;
}

const GalleryImage: FC<GalleryImageProps> = ({ src }) => {
  const [modal, setModal] = useState<boolean>(false);

  const handleModalOpen = useCallback(() => {
    setModal(true);
  }, [modal]);

  const handleModalClose = useCallback(() => {
    setModal(false);
  }, [modal]);

  return (
    <>
      <Box
        sx={{
          borderRadius: '5px',
          display: 'block',
          width: '100%',
          cursor: 'pointer',
        }}
        component="img"
        src={src}
        srcSet={src}
        alt="gallery image"
        loading="lazy"
        onClick={handleModalOpen}
      />
      <GalleryModal isOpen={modal} onClose={handleModalClose} src={src} />
    </>
  );
};

export default GalleryImage;
