import React from 'react';
import { Container, Box } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import { GalleryImage } from 'components/gallery';
import { selectImagesData } from 'store/selectors/pageSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

const Gallery = () => {
  const { qr_slider } = useAppSelector(selectImagesData);

  return (
    <Box sx={{ padding: { xs: '30px 0', md: '40px 0' } }}>
      <Container>
        <Box sx={{ width: '100%', minHeight: '80vh' }}>
          <Masonry sx={{ margin: '0' }} columns={{ xs: 2, sm: 3 }} spacing={2}>
            {qr_slider.map((item) => (
              <GalleryImage key={item.id} src={item.src} />
            ))}
          </Masonry>
        </Box>
      </Container>
    </Box>
  );
};

export default Gallery;
